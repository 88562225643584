import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { paths } from 'src/constants';
import { selectors } from 'src/store';
import { date } from 'src/utils';

const SESSION_VISIT_KEY = 'hasOneSessionVisitPromo';
const MAX_SESSION_VISITS = 1;

function getHasEnoughAppearances() {
  const sessionVisits = sessionStorage.getItem(SESSION_VISIT_KEY);
  const sessionVisitsNum = parseInt(sessionVisits, 10);
  const hasEnoughSessionVisits = sessionVisitsNum >= MAX_SESSION_VISITS;

  return hasEnoughSessionVisits;
}

function increaseSessionVisits() {
  const sessionVisits = sessionStorage.getItem(SESSION_VISIT_KEY) || 0;
  const sessionVisitsNum = parseInt(sessionVisits, 10);
  const newSessionVisitsNum = sessionVisitsNum + 1;
  const newSessionVisits = newSessionVisitsNum.toString();
  sessionStorage.setItem(SESSION_VISIT_KEY, newSessionVisits);
}

const usePromo = () => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [autoShowModal, setAutoShowModal] = useState(false);
  const hasAutoShowModal = useRef(false);

  const isSpeechTherapist = useSelector(
    selectors.authentication.getIsSpeechTherapist
  );
  const subscriptionStatus = useSelector(
    selectors.subscriptionStatus.getSubscriptionStatus
  );
  const userCreatedAt = useSelector(selectors.user.getCreatedAt);

  const handleShowModal = useCallback(() => {
    increaseSessionVisits();
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setAutoShowModal(false);
  }, []);

  const handleCTAClick = useCallback(() => {
    handleClose();
    history.push(paths.SUBSCRIPTIONS);
  }, [handleClose, history]);

  useEffect(() => {
    const isParent = !isSpeechTherapist;
    const hasSubscription = !!subscriptionStatus.isSubscription;
    const isNewlyCreatedUser =
      userCreatedAt && new Date(userCreatedAt) > new Date('2024-12-30');
    const isPromoValid = date.getIsPromoDate() && isNewlyCreatedUser;

    const hasBaseConditions = isParent && !hasSubscription && isPromoValid;

    const hasEnoughAppearances = getHasEnoughAppearances();
    const shouldAutoShowPromoModal = hasBaseConditions && !hasEnoughAppearances;

    hasAutoShowModal.current = shouldAutoShowPromoModal;
    setAutoShowModal(shouldAutoShowPromoModal);
  }, [isSpeechTherapist, subscriptionStatus.isSubscription, userCreatedAt]);

  return {
    showModal: showModal || autoShowModal,
    hasAutoShowModal: !!hasAutoShowModal.current,
    setShowModal,
    handleShowModal,
    handleClose,
    handleCTAClick,
  };
};

export default usePromo;
