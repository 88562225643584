import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { paths } from 'src/constants';
import { use50Chance } from 'src/hooks';
import { selectors } from 'src/store';
import { date, subscriptions as subscriptionsUtils } from 'src/utils';

const SESSION_VISIT_KEY = 'hasOneSessionVisitEkspert';
const MAX_SESSION_VISITS = 1;

const LOCAL_APPEARANCES_KEY = 'hasEnoughLocalAppearancesEkspert';
const MAX_LOCAL_APPEARANCES = 10;

function getHasEnoughAppearances() {
  const sessionVisits = sessionStorage.getItem(SESSION_VISIT_KEY);
  const sessionVisitsNum = parseInt(sessionVisits, 10);
  const hasEnoughSessionVisits = sessionVisitsNum >= MAX_SESSION_VISITS;

  const localAppearances = localStorage.getItem(LOCAL_APPEARANCES_KEY);
  const localAppearancesNum = parseInt(localAppearances, 10);
  const hasEnoughLocalVisits = localAppearancesNum >= MAX_LOCAL_APPEARANCES;

  return hasEnoughSessionVisits || hasEnoughLocalVisits;
}

function increaseSessionVisits() {
  const sessionVisits = sessionStorage.getItem(SESSION_VISIT_KEY) || 0;
  const sessionVisitsNum = parseInt(sessionVisits, 10);
  const newSessionVisitsNum = sessionVisitsNum + 1;
  const newSessionVisits = newSessionVisitsNum.toString();
  sessionStorage.setItem(SESSION_VISIT_KEY, newSessionVisits);
}

function increaseAppearances() {
  const localAppearances = localStorage.getItem(LOCAL_APPEARANCES_KEY) || 0;
  const localAppearancesNum = parseInt(localAppearances, 10);
  const newLocalAppearancesNum = localAppearancesNum + 1;
  const newLocalAppearances = newLocalAppearancesNum.toString();
  localStorage.setItem(LOCAL_APPEARANCES_KEY, newLocalAppearances);
}

function fillAppearances() {
  localStorage.setItem(LOCAL_APPEARANCES_KEY, MAX_LOCAL_APPEARANCES.toString());
}

const useEkspertPromo = () => {
  const is50Chance = use50Chance();

  const [showModal, setShowModal] = useState(false);
  const [autoShowModal, setAutoShowModal] = useState(false);
  const hasAutoShowModal = useRef(false);

  const isSpeechTherapist = useSelector(
    selectors.authentication.getIsSpeechTherapist
  );
  const subscriptionStatus = useSelector(
    selectors.subscriptionStatus.getSubscriptionStatus
  );
  const userCreatedAt = useSelector(selectors.user.getCreatedAt);

  const handleShowModal = useCallback(() => {
    increaseAppearances();
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setAutoShowModal(false);
  }, []);

  const handleCTAClick = useCallback(() => {
    handleClose();
    fillAppearances();
    window.open(paths.landingPage.KOKOLINGO_EKSPERT, '_blank');
  }, [handleClose]);

  useEffect(() => {
    const isParent = !isSpeechTherapist;
    const hasSubscription = !!subscriptionStatus.isSubscription;
    const hasExpertDomain = !!subscriptionStatus.hasExpertDomain;
    const isNewlyCreatedUser =
      userCreatedAt && new Date(userCreatedAt) > new Date('2024-12-30');
    const isPromoValid = date.getIsPromoDate() && isNewlyCreatedUser;

    const hasBaseConditions =
      subscriptionsUtils.getIsEkspertEnabled() &&
      isParent &&
      (isPromoValid ? hasSubscription : true) &&
      !hasExpertDomain;

    const hasEnoughAppearances = getHasEnoughAppearances();
    const hasAllShowConditions = hasBaseConditions && !hasEnoughAppearances;

    if (hasAllShowConditions) {
      increaseSessionVisits();
    }

    const isUsing50Chance = hasSubscription ? is50Chance : true;
    const shouldAutoShowPromoModal = isUsing50Chance && hasAllShowConditions;

    hasAutoShowModal.current = shouldAutoShowPromoModal;
    setAutoShowModal(shouldAutoShowPromoModal);
  }, [
    is50Chance,
    isSpeechTherapist,
    subscriptionStatus.hasExpertDomain,
    subscriptionStatus.isSubscription,
    userCreatedAt,
  ]);

  return {
    showModal: showModal || autoShowModal,
    hasAutoShowModal: !!hasAutoShowModal.current,
    setShowModal,
    handleShowModal,
    handleClose,
    handleCTAClick,
  };
};

export default useEkspertPromo;
